import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['form', 'input', 'openButton', 'submitButton'];

    declare formTarget: HTMLFormElement;
    declare inputTarget: HTMLInputElement;
    declare openButtonTarget: HTMLButtonElement;
    declare submitButtonTarget: HTMLButtonElement;

    select(ev: any) {
        const res = ev.target as HTMLElement;
        const agentId = res.dataset.agentId;
        const agentName = res.dataset.agentName;

        if (agentId == null || agentName == null) {
            return
        }

        this.inputTarget.value = agentId;
        this.openButtonTarget.innerHTML = agentName;
        this.submitButtonTarget.click();
    }
}
