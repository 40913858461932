import { Controller } from "@hotwired/stimulus"
// @ts-ignore
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ['menu', 'menuBackdrop', 'menuContainer', 'openButton', 'closeButton']

  open: boolean = false

  declare menuTarget: HTMLElement
  declare menuBackdropTarget: HTMLElement
  declare menuContainerTarget: HTMLElement

  hide() {
    leave(this.menuTarget)
    leave(this.menuBackdropTarget)
    leave(this.menuContainerTarget)
    this.open = false
  }

  show() {
    this.open = true
    enter(this.menuTarget)
    enter(this.menuBackdropTarget)
    enter(this.menuContainerTarget)
  }
}
