// @ts-ignore
import { Turbo } from "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"
import * as ActiveStorage from "@rails/activestorage"

import './alpine'
import "./controllers"

import "trix"

ActiveStorage.start()

Turbo.setConfirmMethod(() => {
  const dialog = document.getElementById('turbo-confirm') as HTMLDialogElement;
  dialog.showModal()

  return new Promise((resolve, reject) => {
    dialog.addEventListener('close', () => {
      resolve(dialog.returnValue === 'confirm')
    })
  })
})
