import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "modal"]
  
  declare contentTarget: HTMLElement
  declare modalTarget: HTMLElement
  
  hideModal() {
    // @ts-ignore
    this.element.parentElement.removeAttribute("src")
    this.modalTarget.remove()
  }
  
  closeWithKeyboard(e: KeyboardEvent) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }
  
  closeBackground(e: MouseEvent) {
    if (e && this.contentTarget.contains(<Node>(e!.target))) {
      return
    }
    this.hideModal()
  }
}
