import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['bulk', 'notification']

    declare readonly bulkTarget: HTMLButtonElement
    declare readonly notificationTargets: Array<HTMLElement>

    connect() {
        this.sync()
    }

    private selected(id: Number): Boolean {
        const checkbox = this.checkbox(this.notification(id));
        if (checkbox == null) {
            return false;
        }

        return checkbox.checked;
    }

    private checkbox(notification: HTMLElement | undefined): HTMLInputElement | undefined {
        if (notification === undefined) {
            return undefined
        }

        if (notification.querySelectorAll('input').length === 0) {
            return undefined
        }

        return notification.querySelectorAll('input')[0];
    }

    private notification(id: Number): HTMLElement | undefined {
        return this.notificationTargets.find(tgt => {
            const tgtId = tgt.dataset.id;
            if (tgtId == null) {
                return false
            }

            tgtId === id.toString()
        })
    }

    sync() {
        let selectedCount = 0;
        this.notificationTargets.forEach(((tgt: HTMLElement) => {
            const checkbox = this.checkbox(tgt);
            if (checkbox == null) {
                return;
            }

            if (checkbox!.checked) {
                selectedCount += 1
            }
        }).bind(this))

        this.bulkTarget.disabled = selectedCount == 0;
    }
}
