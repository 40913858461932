import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["submit"];

    declare submitTarget: HTMLButtonElement;

    submit() {
        this.submitTarget.click();
    }
}
