// can only find integer id's. will not work with uuid's
export const idFromDomId = (domId: string): Number | null => {
    const idMatches = domId.match(/\d{1,}/)
    if (idMatches == null) {
        return null
    }

    const idStr = idMatches[0]
    return parseInt(idStr)
}

