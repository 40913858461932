import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    minDate: String,
    maxDate: String,
    beginDateInputId: String,
    endDateInputId: String,
  }

  declare minDateValue: string;
  declare maxDateValue: string;
  declare beginDateInputIdValue: string;
  declare endDateInputIdValue: string;

  fillDate() {
    const beginInputEl = (<HTMLInputElement>document.getElementById(this.beginDateInputIdValue))
    beginInputEl.value = this.minDateValue;

    const endInputEl = (<HTMLInputElement>document.getElementById(this.endDateInputIdValue))
    endInputEl.value = this.maxDateValue;
  }
}
