import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fee", "feeUnit"];

  declare feeTarget: HTMLInputElement;
  declare feeUnitTarget: HTMLSelectElement;

  connect() {
    if(this.feeUnitTarget.value == 'percentage') {
      this.feeTarget.max = '100';
    };
  }

  clearFee(ev: any) {
    this.feeTarget.value = '';

    if(ev.target.value == 'percentage') {
      this.feeTarget.max = '100';
    } else {
      this.feeTarget.max = '';
    }
  }
}
