import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["tab"];

    declare tabTargets: HTMLElement[];

    setTab(ev: Event) {
    }
}

