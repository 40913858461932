import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['button', 'query', 'results', 'selection']

    declare buttonTarget: HTMLButtonElement
    declare queryTarget: HTMLInputElement
    declare resultsTarget: HTMLElement
    declare selectionTarget: HTMLInputElement

    selectResult(ev: any) {
        const res = ev.target
        this.queryTarget.value = res.innerHTML
        this.selectionTarget.value = res.dataset.resultId
        this.clearResults()
    }

    clearResults() {
        this.resultsTarget.innerHTML = "";
    }

    search() {
        if (this.queryTarget.value === "") {
            this.clearResults()
            return
        }
        this.buttonTarget.click();
    }
}
