import { ApplicationController as StimulusHookController, useDebounce } from 'stimulus-use'

export default class extends StimulusHookController {
    static debounces = ['suggestions'];
    static targets = [
      'coords',
      'input',
      'results',
      'suggestions',
      'suggestionList',
      'search'
    ];

    declare coordsTarget: HTMLInputElement;
    declare inputTarget: HTMLInputElement;
    declare suggestionsTarget: HTMLButtonElement;
    declare suggestionListTarget: HTMLElement;
    declare searchTarget: HTMLButtonElement;

    connect() {
      useDebounce(this, { wait: 500 });
    }

    hideSuggestions() {
      // if we hide the suggestions too quickly the links won't be clickable
      setTimeout((() => {
        this.suggestionListTarget.classList.add('invisible');
      }).bind(this), 150);
    }

    showSuggestions() {
      this.suggestionListTarget.classList.remove('invisible');
    }

    selectResult(ev: any) {
      const name = ev.currentTarget.dataset.locationName;
      const coords = ev.currentTarget.dataset.locationCoords;
      this.inputTarget.value = name;
      this.coordsTarget.value = coords;
      this.suggestionListTarget.classList.add('invisible');
    }

    suggestions() {
      this.coordsTarget.value = '';
      this.suggestionsTarget.click();
    }
}
