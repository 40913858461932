import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source', 'notification']

  static values = {
    source: String
  }

  declare hasNotificationTarget: boolean;
  declare hasSourceValue: boolean;
  declare notificationTarget: HTMLElement;
  declare sourceTarget: HTMLElement;
  declare sourceValue: string;

  copy(event: any) {
    event.preventDefault()
    if (this.hasSourceValue) {
      navigator.clipboard.writeText(this.sourceValue);
    } else {
      navigator.clipboard.writeText(this.sourceTarget.innerText)
    }

    if (this.hasNotificationTarget) {
      this.notificationTarget.classList.remove('hidden');
      setTimeout((() => {
        this.notificationTarget.classList.add('hidden');
      }).bind(this), 2500)
    }
  }

  hideNotification() {
    this.notificationTarget.classList.add('hidden');
  }
}
