import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['input'];

    declare inputTarget: HTMLInputElement;

    clear(ev: any) {
        this.inputTarget.value = '';
    }
}
