import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input"];

    declare inputTarget: HTMLInputElement;

    change(ev: Event) {
        const tgt = ev.target as any;
        if (tgt == null) {
            return
        }

        if (!tgt.checked) {
            console.log(this.inputTarget);
            this.inputTarget.value = '';
        }
    }
}
