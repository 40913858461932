import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["form"];

    declare formTarget: HTMLFormElement;

    reset() {
        this.formTarget.reset();
    }
}
