// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application";

// @ts-ignore
import { Dropdown, Tabs } from "tailwindcss-stimulus-components";

// @ts-ignore
import { Multiselect } from "@wizardhealth/stimulus-multiselect";

// @ts-ignore
import Carousel from "stimulus-carousel";

// @ts-ignore
// this is handled by the esbuild-rails plugin (see https://github.com/excid3/esbuild-rails)
import controllers from "./**/*_controller.ts";
// @ts-ignore
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default);
});

application.register("carousel", Carousel);
application.register("dropdown", Dropdown);
application.register("tabs", Tabs);
application.register("multiselect", Multiselect);
