import { Controller } from "@hotwired/stimulus"
import amplitude from "amplitude-js"

const submitButton = (element: HTMLElement) => {
  if (element.tagName !== 'BUTTON') {
    return false
  }

  const button = element as HTMLButtonElement;

  return button.type === 'submit' || button.type == null;
}

const formToJson = (form: HTMLFormElement) => {
  const data = new FormData(form);

  data.delete('authenticity_token');

  return Object.fromEntries(data);
}

export default class extends Controller {
  initialize(): void {
    amplitude.getInstance().init(this.metaTagContent('amplitude_key'), this.metaTagContent('user_id'))
  }

  connect(): void {
    const clickables = document.querySelectorAll('[data-track-click]') as NodeListOf<HTMLElement>
    this.trackClickables(clickables);

    const submittables = document.querySelectorAll('[data-track-submit]') as NodeListOf<HTMLElement>
    this.trackSubmittables(submittables);
  }

  logEvent(event: string, metadata: any = {}) {
    amplitude.getInstance().logEvent(event, { ...metadata, impersonation: this.metaTagContent('impersonation') })
  }

  logFormSubmission(name: string, form: HTMLFormElement) {
    this.logEvent(`submitted ${name}`, formToJson(form))
  }

  trackClickables(clickables: NodeListOf<HTMLElement>) {
    clickables.forEach(element => {
      element.addEventListener('click', () => {
        this.logEvent(`clicked ${element.dataset.trackClick}`)
      })
    })
  }

  trackSubmittables(submittables: NodeListOf<HTMLElement>) {
    submittables.forEach(element => {
      if (submitButton(element)) {
        const button = element as HTMLButtonElement;

        if (button.form == null) {
          console.warn(`no form found for button ${button}`)
          return
        }

        button.addEventListener('click', () => {
          this.logFormSubmission(button.dataset.trackSubmit!, button.form!)
        })

        return
      }

      if (element.tagName === 'FORM') {
        element.addEventListener('submit', () => {
          this.logFormSubmission(element.dataset.trackSubmit!, element as HTMLFormElement)
        });

        return
      }

      console.warn(`cannot track submission. ${element} is neither a submit button or form`)
    })
  }

  updateAgent(e: MouseEvent) {
    const element = e.target as HTMLElement
    const fieldName = element.dataset.fieldName
    this.logEvent(`updated agent ${fieldName}`)
  }

  metaTagContent(tagName: string): string {
    const tag = document.head.querySelector(`meta[name=${tagName}]`)
    return tag instanceof HTMLMetaElement ? tag.content : ''
  }
}
