import {Controller} from '@hotwired/stimulus';

const CLOSE_TIME = 5000

export default class extends Controller {
  static targets = ['progress']

  declare progressTarget: HTMLElement;

  startTime = new Date().getTime()

  connect() {
    setTimeout(this.close.bind(this), CLOSE_TIME)
    this.updateProgress()
  }

  close() {
    this.element.remove()
  }

  updateProgress() {
    const rawProgress = (new Date().getTime() - this.startTime) / 5000
    const roundedProgress = Math.round(rawProgress * 100)

    this.progressTarget.style.width = `${roundedProgress}%`

    if (roundedProgress < 100) {
      window.requestAnimationFrame(this.updateProgress.bind(this))
    }
  }
}
