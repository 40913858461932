import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element"];

  declare elementTargets: HTMLElement[];

  toggle() {
    this.elementTargets.forEach((el) => {
      el.classList.toggle("hidden");
    });
  }
}
