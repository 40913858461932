import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["chevron", "content"]

  static values = {
    open: Boolean,
    name: String,
  }

  declare chevronTarget: HTMLElement
  declare contentTarget: HTMLElement

  declare nameValue: string
  declare openValue: boolean

  connect() {
    const storedOpenValue = localStorage.getItem(this.nameValue + 'Open')

    console.log('storedOpenValue', storedOpenValue)

    if (storedOpenValue != null) {
      this.openValue = storedOpenValue != '0';
    }

    this.render()
  }

  toggle() {
    this.setOpenValue(!this.openValue)

    this.render()
  }

  private setOpenValue(value: boolean) {
    this.openValue = value
    console.log('saving value', value)
    localStorage.setItem(this.nameValue + 'Open', (value ? 1 : 0).toString())
  }

  private render() {
    if (this.openValue) {
      this.contentTarget.classList.remove("hidden")
      this.chevronTarget.classList.remove("fa-chevron-up")
      this.chevronTarget.classList.add("fa-chevron-left")
    } else {
      this.contentTarget.classList.add("hidden")
      this.chevronTarget.classList.remove("fa-chevron-left")
      this.chevronTarget.classList.add("fa-chevron-up")
    }
  }
}
