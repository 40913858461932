// @ts-ignore
import Alpine from 'alpinejs'
// @ts-ignore
import mask from '@alpinejs/mask'
// @ts-ignore
import persist from '@alpinejs/persist'

Alpine.plugin(mask)
Alpine.plugin(persist)

// @ts-ignore
window.Alpine = Alpine

Alpine.start();

