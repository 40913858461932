import { Controller } from "@hotwired/stimulus"
import Cropper from "cropperjs"

export default class extends Controller {
    static targets = ['image', 'xInput', 'yInput', 'heightInput', 'widthInput']

    declare imageTarget: HTMLImageElement
    declare xInputTarget: HTMLInputElement
    declare yInputTarget: HTMLInputElement
    declare heightInputTarget: HTMLInputElement
    declare widthInputTarget: HTMLInputElement
    cropper: any;

    connect() {
        this.cropper = new Cropper(
            this.imageTarget,
            {
                aspectRatio: 1,
                viewMode: 1,
                crop: (event) => {
                    this.xInputTarget.value = event.detail.x.toString()
                    this.yInputTarget.value = event.detail.y.toString()
                    this.heightInputTarget.value = event.detail.height.toString()
                    this.widthInputTarget.value = event.detail.width.toString()
                }
            }
        )
    }
}
