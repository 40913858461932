import { ApplicationController as StimulusHookController, useDebounce } from 'stimulus-use'

export default class extends StimulusHookController {
    static debounces = ['suggestions'];
    static targets = ['input', 'results', 'suggestions', 'suggestionList', 'search'];

    declare inputTarget: HTMLInputElement;
    declare resultsTarget: HTMLElement;
    declare suggestionsTarget: HTMLButtonElement;
    declare suggestionListTarget: HTMLElement;
    declare searchTarget: HTMLButtonElement;

    connect() {
      useDebounce(this, { wait: 500 });
    }

    clearSuggestions() {
        this.resultsTarget.innerHTML = "";
    }

    hideSuggestions() {
      // if we hide the suggestions too quickly the links won't be clickable
      setTimeout((() => {
        this.suggestionListTarget.classList.add('invisible');
      }).bind(this), 150);
    }

    showSuggestions() {
      this.suggestionListTarget.classList.remove('invisible');
    }

    selectResult(ev: any) {
        const res = ev.target;
        this.inputTarget.value = res.innerHTML.trim();
        this.clearSuggestions();
        this.search();
    }

    suggestions() {
        this.suggestionsTarget.click();
    }

    search() {
        const trimmedQuery = this.inputTarget.value.trim();
        this.searchTarget.click();
    }
}
