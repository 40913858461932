import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    close(e: MouseEvent) {
        if (e && this.element.contains(<Node>(e!.target))) {
            return
        }

        this.element.remove()
    }
}
