import { Controller } from "@hotwired/stimulus"
// @ts-ignore
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ['sidebar', 'backdrop', 'container', 'openButton', 'closeButton']

  open: boolean = false

  declare sidebarTarget: HTMLElement
  declare backdropTarget: HTMLElement
  declare containerTarget: HTMLElement

  hide() {
    leave(this.sidebarTarget)
    leave(this.backdropTarget)
    leave(this.containerTarget)
    this.open = false
  }

  show() {
    this.open = true
    enter(this.sidebarTarget)
    enter(this.backdropTarget)
    enter(this.containerTarget)
  }
}
