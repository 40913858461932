import { Controller } from "@hotwired/stimulus"

const isValidUrl = (url: string) => {
    try {
      new URL(url);
    } catch (e) {
      return false;
    }
    return true;
};

const dedupeUrl = (baseUrl: string, value: string) => {
    let strippedVal = value.replace(baseUrl, '');

    if (isValidUrl(strippedVal)) {
        return strippedVal;
    } else {
        return value;
    }
}

export default class extends Controller {
    static targets = ['input']
    static values = {
        baseUrl: String
    }

    declare inputTarget: HTMLInputElement;
    declare baseUrlValue: string;

    format() {
        return
        const value = this.inputTarget.value;
        if (isValidUrl(value)) {
            // deduplicate url's in case the user pasted a url in. if the field was already populated they would
            // end up with the baseUrl + their url. i.e. https://facebook.com/https://facebook.com/user.id
            this.inputTarget.value = dedupeUrl(this.baseUrl, value);
            return
        }
        const url = this.baseUrlValue + value;
        this.inputTarget.value = url;
    }

    get baseUrl() {
        return this.baseUrlValue;
    }
}
