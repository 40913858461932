import { Controller } from '@hotwired/stimulus'
import { idFromDomId } from '../../utils/identification'

export default class extends Controller {
    static targets = ['input', 'wrapper', 'submit']

    declare inputTarget: HTMLInputElement;
    declare wrapperTarget: HTMLElement;
    declare submitTarget: HTMLButtonElement;

    sync() {
        // get id from tubro-frame within wrapper
        const frameId = this.wrapperTarget.querySelector('turbo-frame')!.id;
        const contactId = idFromDomId(frameId);

        if (contactId == null) {
            return
        }

        // set value of inputTarget to be id
        this.inputTarget.value = contactId.toString();

        // 'click' on submit button
        this.submitTarget.click();
    }
}
